import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Footer from '../../Components/Footer/Footer';
import './FindJob.css';

const FindJob = () => {
    const [location, setLocation] = useState('');
    const [jobs, setJobs] = useState([]);

    const predefinedLocations = ['Remote', 'Brooklyn', 'Monsey'];

    const fetchJobsByLocation = async (selectedLocation) => {
        if (selectedLocation) {
            const jobCollection = collection(db, 'postedjobs');
            const locationQuery = query(jobCollection, where('location', '==', selectedLocation));

            try {
                const querySnapshot = await getDocs(locationQuery);
                const jobList = [];
                querySnapshot.forEach((doc) => {
                    jobList.push(doc.data());
                });
                setJobs(jobList);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        }
    };

    useEffect(() => {
        fetchJobsByLocation(location);
    }, [location]);

    return (
        <div>
            <NavigationBar />
            <div className="find-job-box">
                <div className="find-job-container">
                    <h1 className="find-job-heading">Find a Job</h1>
                    <div className="custom-select-container">
                        <select
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="custom-select"
                        >
                            <option value="">Select Location</option>
                            {predefinedLocations.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {jobs.length === 0 && location !== '' && (
                <p>No jobs found in {location}.</p>
            )}

            <div className="job-list">
                {jobs.map((job, index) => (
                    <div key={index} className="job-list-item">
                        <h2>{job.jobTitle}</h2>
                        <p>Company: {job.companyName}</p>
                        <p>Location: {job.location}</p>
                        <p>Phone Number: {job.phoneNumber}</p>
                        <p>Email: {job.email}</p>
                        <p>Description: {job.jobDescription}</p> {/* Display job description */}
                    </div>
                ))}
            </div>

            <Footer />
        </div>
    );
};

export default FindJob;
