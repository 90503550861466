import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../Components/NavigationBar/NavigationBar'; // Adjust the path as needed
import Footer from '../../Components/Footer/Footer'; // Adjust the path as needed
import './HomePage.css';

const HomePage = () => {
    const navigate = useNavigate();

    // Function to navigate to the FindJob page
    const navigateToFindJob = () => {
        navigate('/findjob');
    };

    // Function to navigate to the PostJob page
    const navigateToPostJob = () => {
        navigate('/postjob');
    };

    return (
        <div>
            <NavigationBar />
            <main>
                <div className="buttons-container">
                    <button className="big-button" onClick={navigateToFindJob}>Find A Job</button>
                    <p className="or-text">or</p>
                    <button className="big-button" onClick={navigateToPostJob}>Post A Job</button>
                </div>
                {/* Additional content for your HomePage goes here */}
            </main>
            <Footer />
        </div>
    );
};

export default HomePage;
