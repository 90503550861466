import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { app } from '../firebase'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css'; // Importing the CSS styles

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const auth = getAuth(app);
    const googleProvider = new GoogleAuthProvider();

    const signUpWithEmail = async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/home'); // Redirect to the homepage
        } catch (error) {
            setError(error.message);
        }
    };

    const signUpWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/home'); // Redirect to the homepage upon successful sign-in
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <h1>Sign Up</h1>
                {error && <p className="auth-error">{error}</p>}
                <div>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="auth-input"
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="auth-input"
                    />
                    <button onClick={signUpWithEmail} className="auth-button">
                        Sign Up
                    </button>
                    <button onClick={signUpWithGoogle} className="auth-button google-sign-in">
                        Sign Up with Google
                    </button>
                </div>
                <p>
                    Already have an account? <a href="/signin" className="auth-link">Sign In</a>
                </p>
            </div>
        </div>
    );
};

export default SignUp;
