import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '../../Components/NavigationBar/NavigationBar';
import Footer from '../../Components/Footer/Footer';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './PostJob.css';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';

const PostJob = () => {
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [location, setLocation] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [email, setEmail] = useState('');

    const auth = getAuth();
    const user = auth.currentUser;
    const userUID = user ? user.uid : null;

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!location || !email) {
            setSubmissionStatus('error');
            return;
        }

        const jobPostingCollection = collection(db, 'postedjobs');

        const newJobPosting = {
            jobTitle,
            companyName,
            location,
            jobDescription,
            userUID,
            phoneNumber,
            email,
        };

        try {
            await addDoc(jobPostingCollection, newJobPosting);
            setSubmissionStatus('success');
            navigate('/payment');
        } catch (error) {
            console.error('Error posting job:', error);
            setSubmissionStatus('error');
        }

        setJobTitle('');
        setCompanyName('');
        setLocation('');
        setJobDescription('');
        setPhoneNumber('');
        setEmail('');
    };

    return (
        <div>
            <NavigationBar />
            <main className="post-job-main">
                <div className="post-job-container">
                    <h1 className="post-job-heading">Post a Job</h1>
                    {submissionStatus === 'success' && (
                        <p className="success-message">Job posted successfully!</p>
                    )}
                    {submissionStatus === 'error' && (
                        <p className="error-message">Error posting job. Please try again.</p>
                    )}
                    <form className="post-job-form" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={jobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                            placeholder="Job Title"
                            required
                        />
                        <input
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Company Name"
                            required
                        />
                        <input
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                            required
                        />
                        <textarea
                            value={jobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                            placeholder="Job Description"
                        />
                        <PhoneInput
                            value={phoneNumber}
                            onChange={setPhoneNumber}
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                        />
                        <button type="submit" className="post-job-submit">Post Job</button>
                    </form>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PostJob;
