import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { app } from '../firebase';
import { useNavigate } from 'react-router-dom';
// import './AuthStyles.css'; // Importing your custom CSS styles
import classes from './GoogleButtons.module.css';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const auth = getAuth(app);

    const signInWithEmail = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/home');
        } catch (error) {
            setError(error.message);
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            navigate('/home');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-form">
                <h1>Sign In</h1>
                {error && <p className="auth-error">{error}</p>}
                
                {/* Email Input Field */}
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="auth-input"
                />

                {/* Password Input Field */}
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="auth-input"
                />

                {/* Sign In with Email Button */}
                <button onClick={signInWithEmail} className="auth-button">Sign In with Email</button>

                {/* Google Sign-In Button */}
                <button onClick={signInWithGoogle} className={classes['gsi-material-button']}>
                    <div className="gsi-material-button-content-wrapper">
                        <span className="gsi-material-button-contents">
                            Sign In with Google
                        </span>
                    </div>
                </button>

                <p>
                    Don't have an account? <a href="/signup" className="auth-link">Sign Up</a>
                </p>
            </div>
        </div>
    );
};

export default SignIn;
