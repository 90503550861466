import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SignIn from './Authentication/SignIn';
import SignUp from './Authentication/SignUp';
import ProtectedRoute from './Authentication/ProtectedRoute';
import HomePage from './Pages/HomePage/HomePage';
import FindJob from './Pages/FindJob/FindJob';
import PostJob from './Pages/PostJob/PostJob';
import UserProfile from './Pages/Profile/UserProfile';
import About from './Pages/About/About';
import PaymentForm from './Components/PaymentForm';
import ConfirmationPage from './Components/ConfirmationPage';
import './App.css';

// Replace this with your Stripe public key
const stripePromise = loadStripe('pk_test_51OQxQzEx6o7uQqhYX2DtKghmpdbMO7laYjA8ybj23uAvQ2LSRXD53Bia8C3TiA9nfQqv2k9s99pr0gOa49NuNazx00jJtMRM1z');

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/home" element={<ProtectedRoute component={HomePage} />} />
          <Route path="/findjob" element={<ProtectedRoute component={FindJob} />} />
          <Route path="/postjob" element={<ProtectedRoute component={PostJob} />} />
          <Route path="/userprofile" element={<ProtectedRoute component={UserProfile} />} />
          <Route path="/about" element={<About />} />
          <Route path="/payment" element={
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          } />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/" element={<Navigate replace to="/home" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
