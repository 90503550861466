import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './PaymentForm.css';

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate(); // Initialize navigate

    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (result.error) {
            setPaymentError(result.error.message);
            setPaymentSuccess(null);
        } else {
            // Additional server-side processing can be done here

            setPaymentSuccess('Payment successful!');
            setPaymentError(null);

            // Redirect to the confirmation page
            navigate('/confirmation'); // Replace '/confirmation' with your route
        }
    };

    return (
        <div className="payment-form-container">
            <h2 className="payment-form-title">Payment</h2>
            <form onSubmit={handleSubmit} className="payment-form">
                <label className="payment-form-label">
                    Card details
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                        className="payment-form-card-element"
                    />
                </label>
                <button type="submit" disabled={!stripe} className="payment-form-submit">
                    Pay
                </button>
            </form>
            {paymentError && <div className="error-message">{paymentError}</div>}
            {paymentSuccess && (
                <div className="success-message">{paymentSuccess}</div>
            )}
        </div>
    );
};

export default PaymentForm;
