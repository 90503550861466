import React from 'react';
import './ConfirmationPage.css';

const ConfirmationPage = () => {
    return (
        <div className="confirmation-container">
            <div className="confirmation-content">
                <h1>Success!</h1>
                <p>Your job posting has been successfully submitted and paid for.</p>
                <button onClick={() => window.location.href = '/home'}>
                    Go to Home
                </button>
            </div>
        </div>
    );
};

export default ConfirmationPage;
