import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleSignOut = async () => {
        const confirmation = window.confirm("Are you sure you want to sign out?");
        if (confirmation) {
            try {
                await signOut(auth);
                navigate('/signin');
            } catch (error) {
                console.error('Sign Out Error', error);
            }
        }
    };

    const goToUserProfile = () => {
        navigate('/userprofile'); // Make sure this route matches your UserProfile route
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand" onClick={() => navigate('/home')}>KosherStaffing.com</div>
            <ul className="nav-ul">
                <li className="nav-li">
                    <a className="nav-link" href="/about">About</a>
                </li>
                <li className="nav-li">
                    <button onClick={goToUserProfile} className="nav-link">Profile</button>
                </li>
                <li className="nav-li">
                    <button onClick={handleSignOut} className="sign-out-btn">Sign Out</button>
                </li>
                {/* Add more links as needed */}
            </ul>
        </nav>
    );
};

export default NavigationBar;
