import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAR3uMj4RaClFyrU73WQGDXM2Y2lWwE5Lw",
  authDomain: "jewishjobs-accfc.firebaseapp.com",
  projectId: "jewishjobs-accfc",
  storageBucket: "jewishjobs-accfc.appspot.com",
  messagingSenderId: "397981906443",
  appId: "1:397981906443:web:52b15b8a02cce46ffd790e",
  measurementId: "G-F8LYP1VHB6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { app, analytics, db };
