import React from 'react';
import NavigationBar from '../../Components/NavigationBar/NavigationBar'; // Adjust the path as needed
import Footer from '../../Components/Footer/Footer'; // Adjust the path as needed
import './About.css'; // Make sure to create and import the CSS file for styling

const About = () => {
    return (
        <div>
            <NavigationBar />
            <main className="about-main">
                <h1>About Us</h1>
                <section className="about-content">
                    <p>Welcome to our website! Here's a little bit about what we do.</p>
                    <p>...</p> {/* Additional content about your site, mission, values, etc. */}
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default About;
