import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import NavigationBar from '../../Components/NavigationBar/NavigationBar'; // Adjust the path as needed
import Footer from '../../Components/Footer/Footer'; // Adjust the path as needed
import './UserProfile.css'; // Importing the CSS styles

const UserProfile = () => {
    const [user, setUser] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, [auth]);

    return (
        <div>
            <NavigationBar />
            <div className="user-profile-container">
                <h1>User Profile</h1>
                {user ? (
                    <div className="user-info">
                        <p><strong>UUID:</strong> {user.uid}</p>
                        <p><strong>Email:</strong> {user.email}</p>
                    </div>
                ) : (
                    <p>No user data available</p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default UserProfile;
